$(function () {
    'use strict';
    // Set X-CSRF-TOKEN header for ajax requests
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        error: function(jqxhr) {
            if(jqxhr.responseText) {
                notifyUser('danger', jqxhr.responseText);
            }
        }
    });

    //Include Submit Button Value on Click for Serialize Function
    $(document).on('click', '[name][value]:button', function(evt){
        var $button = $(evt.currentTarget),
            $input = $button.closest('form').find('input[name="'+$button.attr('name')+'"]');
        if(!$input.length){
            $input = $('<input>', {
                type:'hidden',
                name:$button.attr('name')
            });
            $input.insertAfter($button);
        }
        $input.val($button.val());
    });

    new Selection();
    new MenuHandling();
    new Datepicker();
    new FieldMasks();
    new Search();
    new Dashboard();
    new Person();
    new Service();
    new Efactura(); //check
    new ServiceGroup(); //check
    new Bantep(); //check
    new Password();//check
    new Acquisition();


    //document.addEventListener("turbolinks:load", function() {
        //enable jscroll
        $('ul.pagination:visible:first').hide();
        $('div.scroller').jscroll({
            padding: 1000,
            loadingHtml: '',
            autoTrigger: true,
            nextSelector: '.pagination li.active + li a',
            contentSelector: 'div.scroller',
            callback: function() {
                $('ul.pagination:visible:first').hide();
                $('[data-toggle="tooltip"]').tooltip();
            }
        });

        //remove left border
        if(!$("#sidebar-wrapper").length) {
            $("#wrapper").css("-webkit-transition","none");
            $("#wrapper").css("-moz-transition","none");
            $("#wrapper").css("-o-transition","none");
            $("#wrapper").css("transition","none");
            $("#wrapper").css("padding-left","0");
        }
    //});
});

var Acquisition = function () {
    new EmployeeAutoComplete();

    $(document).on('click', '.addAcquisitionFrom', function (e) {
        $('#hiddenAcquisitionForm')
            .clone()
            .appendTo('#acquisitions-wrapper')
            .removeAttr('id')
            .removeAttr('style')
            .find('select[name="products[]"]')
            .addClass('selectpicker');

        new Selection();
        new Datepicker();
    });
};

var Password = function () {
    //new category block
    var formsubmitted = false;
    $(document).on('click', '.loadinput-block .newField', function (e) {
        $('.newField').attr('disabled',true);
        if (formsubmitted == false) {
            formsubmitted = true;
            $.ajax({
                type: 'POST',
                url: document.location.origin+'/password/loadblock',
                data: { password_topcategory_id: $(this).attr('data-topcategory-id'), is_first: $(this).attr('data-isfirst'), password_category_id: $(this).parent().prev().find('select option:selected').val(), ownname: $(this).parent().prev().find('input[name="ownname"]').val() },
                cache: false
            }).done(function(html) {
                if($(e.target).attr('data-loadafter')) {
                    $(e.target).closest('.loadinput-block').after(html);
                } else {
                    $(e.target).closest('.loadinput-block').before(html);
                }
                formsubmitted = false;
                $('.newField').attr('disabled',false);
            });
        }
        e.preventDefault();
    });

    //delete category block
    $('#password-module').delegate('.delete-category-block', 'click', function () {
        var passwordBlock = $(this);
        if(confirm(passwordBlock.attr('data-text'))) {
            if(typeof passwordBlock.attr('disabled') == 'undefined' || passwordBlock.attr('disabled') != 'disabled') {
                var url = passwordBlock.attr('data-url');
                if(typeof url !== typeof undefined && url !== false) {
                    $.ajax({
                        type: 'POST',
                        url: url,
                        dataType: 'json',
                        cache: false
                    }).done(function(data) {
                        notifyUser('success', data.msg);
                        passwordBlock.closest('.form-group').remove();
                    });
                } else {
                    passwordBlock.closest('.form-group').remove();
                }
            }
        }
    });

    //load custom fields
    $('#password-module').delegate('.field-selection', 'change', function () {
        if($(this).val() != 0) {
            var selector = $(this);
            $.ajax({
                type: 'POST',
                url: document.location.origin+'/password/loadcustomfield',
                data: { field_id: $(this).val(), password_topcategory_id: $(this).attr('data-topcategory-id'), is_first: $(this).attr('data-isfirst'), name: $(this).attr('data-name') },
                cache: false
            }).done(function(html) {
                selector.closest('.field-block').html(html);
            });
        }
    });

    //on generate-password generate new password for next input block
    $(document).on('click', '.generate-password', function (e, data) {
        var field = $(this).closest('.input-group').find('input[type="text"]');
        field.val(randString(field));
    });

    //on submit successful new password block
    $(document).on('submit-successful', '.form-mask', function (e, data) {
        $('#newPassword').modal('hide');
    });

    // Generate a password string
    function randString(id){
        var dataSet = $(id).attr('data-character-set').split(',');
        var possible = '';
        if($.inArray('a-z', dataSet) >= 0){
            possible += 'abcdefghijklmnopqrstuvwxyz';
        }
        if($.inArray('A-Z', dataSet) >= 0){
            possible += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        }
        if($.inArray('0-9', dataSet) >= 0){
            possible += '0123456789';
        }
        if($.inArray('#', dataSet) >= 0){
            possible += '![]{}()%&*$#^<>~@|';
        }
        var text = '';
        for(var i=0; i < $(id).attr('data-size'); i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }
};

var Efactura = function () {
    $(document).on("click", ".open-sendFactura", function () {
        var action = $(this).data('action');
        var companyId = $(this).data('companyid');
        var view = $(this).data('view');
        var ignorefacturasent = $(this).data('ignorefacturasent');
        var searchEmailUrl = $(this).data('searchurl');
        $("#form-efactura").attr('action', action);
        $(".modal-body #companyId").val(companyId);
        $(".modal-body #view").val(view);
        $(".modal-body #ignorefacturasent").val(ignorefacturasent);

        $("#emails").html('');
        searchEMails(searchEmailUrl);
    });

    function searchEMails(searchEmailUrl) {
        if(!$('.modal-body #companyId').val()) return;
        $.getJSON(searchEmailUrl+$('#companyId').val(), function (persons) {
            if(persons.length != 0) {
                $("#personformgroup").show();
                for (var person = 0; person < persons.length; person++) {
                    addPerson(persons[person]);
                }
            } else {
                $("#emails").html($("#trans_no_recipient").html());
            }
        });
    }

    function addPerson(person) {
        $("#emails").append('<div class="input-group m-b"><span class="input-group-addon"><div class="radio-inline custom-control custom-radio"><label><input type="radio" name="recipient" value="'+person.value+'" '+person.checked+' /><span class="custom-control-indicator"></span></label></div> </span><input disabled class="form-control" name="'+person.value+'" value="'+person.label+'"></div>');
    }
};

var Bantep = function () {
    $(document).on('change', '.bantep_selection', function (e) {
        var val = 0;
        $('.bantep_selection option:selected').each(function() {
            val += parseInt($(this).attr('data-value'));
        });

        var calcval = val.toFixed(0);

        var progressbar =  $('.progress_chance_'+$(this).attr('data-projectid'));

        progressbar.attr('aria-valuenow', val);
        progressbar.css('width', calcval+'%');
        progressbar.text(calcval+'%');

        $('#chance').val(calcval);

        if(e.originalEvent !== undefined) {
            $(this).parents('form:first').trigger('submit');
        }
    });
    $(document).on('change', '.hours_box', function (e) {
        if(!$('#budget').val()) return;
        var calchours = 0;
        var calcpercent = 0;
        var budget = parseInt($('#budget').val());
        var maxhours = parseInt(budget/150);

        var roleId = 0;
        var percent = 0;
        $('.hours_box').each(function() {
            var hours = parseInt($(this).val());
            if($.isNumeric(hours) && hours>0) {
                roleId = $(this).attr('data-roleid');
                percent = parseFloat((100/maxhours)*hours);
                if(calcpercent+percent>100) {
                    percent = 100 - calcpercent;
                }
                calcpercent += parseInt(percent.toFixed(0));
                calchours += hours;
                $('.progress_role_'+roleId).css('width', percent.toFixed(0)+'%').attr('aria-valuenow', percent.toFixed(0));
            } else {
                $('.progress_role_'+$(this).attr('data-roleid')).css('width', '0%').attr('aria-valuenow', '0');
            }
        });
        if(calcpercent==99) {
            percent = percent + 100 - calcpercent;
            $('.progress_role_'+roleId).css('width', percent.toFixed(0)+'%').attr('aria-valuenow', percent.toFixed(0));
        }

        var calcrest = ((budget-(calchours*150))/150).toFixed(0);

        $('#calcrest').html(calcrest);
        $('#calchours').html(maxhours);
    });

    $('.hours_box').trigger('change');
    $('.bantep_selection').trigger('change');
};

var ServiceGroup = function () {
    $("#personformgroup").hide();
    new CompanyAutoComplete();
    new AgencyAutoComplete();
    new EmployeeAutoComplete();

    var personIds = [];

    //Company
    $("#company_textfield").on("onSelect textfieldResetted", function() {
        personIds = [];
        searchCompanies(true);
    });
    $("#company_textfield").on("textfieldResetted", function() {
        personIds = [];
        searchAgencies(false);
    });

    function searchCompanies(trigger) {
        if(!$('#company').val()) return;
        if ($('input[name="id_servicegroup"]').length) {
            var url = '/service/getPersons/'+$('#company').val()+'/'+$('input[name="id_servicegroup"]').val();
        } else {
            var url = '/service/getPersons/'+$('#company').val();
        }
        $.getJSON(url, function (persons) {
            if(persons.length != 0) {
                $("#personformgroup").show();
                for (var person = 0; person < persons.length; person++) {
                    addPersonAndFaktura(persons[person]);
                }
            }
            if(trigger == true) {
                searchAgencies(false);
            }
        });
    }

    //Agency
    $("#agency_textfield").on("onSelect", function() {
        personIds = [];
        searchAgencies(true);
    });
    $("#agency_textfield").on("textfieldResetted", function() {
        personIds = [];
        searchCompanies(false);
    });

    function searchAgencies(trigger) {
        if(!$('#agency').val()) return;
        if ($('input[name="id_servicegroup"]').length) {
            var url = '/service/getPersons/'+$('#agency').val()+'/'+$('input[name="id_servicegroup"]').val();
        } else {
            var url = '/service/getPersons/'+$('#agency').val();
        }
        $.getJSON(url, function (persons) {
            if(persons.length != 0) {
                $("#personformgroup").show();
                for (var person = 0; person < persons.length; person++) {
                    addPersonAndFaktura(persons[person]);
                }
            }
            if(trigger == true) {
                searchCompanies(false);
            }
        });
    }

    function addPersonAndFaktura(person) {
        if(personIds.indexOf(person.value) > -1) {
            return;
        }
        personIds.push(person.value);
        $("#persons").append('<div class="input-group m-b"><span class="input-group-addon"><div class="checkbox-inline custom-control custom-checkbox"><label><input type="checkbox" name="persons[]" value="'+person.value+'" '+person.checkedcaretaker+' /><span class="custom-control-indicator"></span></label></div> </span><input disabled class="form-control" name="'+person.value+'" value="'+person.label+'"></div>');
        $("#fakturas").append('<div class="input-group m-b"><span class="input-group-addon"><div class="checkbox-inline custom-control custom-checkbox"><label><input type="checkbox" name="fakturas[]" value="'+person.value+'" '+person.checkedfaktura+' /><span class="custom-control-indicator"></span></label></div> </span><input disabled class="form-control" name="'+person.value+'" value="'+person.label+'"></div>');
    }

    searchCompanies(true);
};

var Service = function () {
    // Autocomplete Service Allocation (Parent Service)
    $('#allocation_textfield').autocomplete({
        serviceUrl: '/service/getServiceGroups',
        dataType: 'json',
        onSelect: function (suggestion) {
            var allocationId = suggestion.data;
            $('#allocation').val(allocationId);
        }
    }).keyup(function () {
        //Reset Allocation ID if nothing entered
        if ($('#allocation_textfield').val() == '' && $('#allocation').val() != '') {
            $('#allocation').val('');
        }
    });

    //If ServiceType changed load SerivceProducts in Background
    $('#servicetype').on('change', function (e) {
        $.getJSON('/service/getServiceProducts/'+this.value, function (options) {
            $("#serviceproduct").html('');
            if(options.length == 0) {
                $("#serviceproduct").prop('disabled', true);
            } else {
                for (var opt = 0; opt < options.length; opt++) {
                    $("#serviceproduct").append('<option value="' + options[opt].value + '" ' + ((options[opt].selected) ? 'selected="selected"' : '') + '>' + options[opt].name + '</option>');
                }
                $("#serviceproduct").prop('disabled', false);
            }
        });
    });

    $(document).on('submit', '.checkForm', function (e) {
        var action = $(this).attr('action');
        if(action.indexOf('uncheck') !== -1) {
            $(this).attr('action', action.replace("uncheck", "check"));
            $(this).find('.checkIcon').attr('class', 'fa fa-square-o checkIcon');
        } else {
            $(this).attr('action', action.replace("check", "uncheck"));
            $(this).find('.checkIcon').attr('class', 'fa fa-check-square-o checkIcon');
        }
    });
};

var Dashboard = function () {
};

var Person = function () {
    $('.workdayscheckbox').change(function() {
        if(this.checked) {
            //remove last hidden field for workdays
            $(this).prev('input').remove();
        } else {
            //add hidden field for workdays
            $(this).before('<input type="hidden" name="workday'+$(this).attr('value')+'[]" value="0"/>');
        }
    });
};

var Selection = function () {
    //set up selectpicker
    $('.selectpicker').selectpicker({
        iconBase: 'fa',
        tickIcon: 'fa-check'
    });
};

var Search = function () {
    var currentRequest = null;

    $(document).on('keyup', '.live-search', function (e) {
        currentRequest = $.ajax({
            url: window.location.pathname,
            method: 'GET',
            data: {
                'search': $(this).val(),
                'getOnlyContent': true
            },
            beforeSend : function()    {
                if(currentRequest != null) {
                    currentRequest.abort();
                }
            },
            success: function (html) {
                $(".scroller").html(html);
                $('ul.pagination:visible:first').hide();
            },
            error: function (data) {
                if (data.msg !== undefined) {
                    notifyUser('danger', data.msg);
                }
            }
        });
    });

    $(document).on('keyup', '.overview-search', function (e) {
        window.history.replaceState(null, null, "?search=" + $(this).val());
    });

};

var MenuHandling = function () {
    $(document).on('click', '#menu-toggle', function (e) {
        e.preventDefault();
        $("#wrapper").toggleClass("toggled");
    });
};

var Datepicker = function () {
    $(document).ready(function(){

        var icons = {
            time: "fa fa-clock-o",
            date: "fa fa-calendar",
            up: "fa fa-arrow-up",
            down: "fa fa-arrow-down"
        };

        $('.form-control.datepicker').datepicker({
            format: 'dd.mm.yyyy',
            weekStart: '1',
            todayHighlight: true,
            icons: icons
        });

        $('.form-control.timepicker').datetimepicker({
            format: 'HH:mm',
            icons: icons
        });

        $('.form-control.datetimepicker').datetimepicker({
            format: 'DD.MM.YYYY HH:mm',
            icons: icons
        });
    });
};

/**
 * All project related functionality.
 *
 * @constructor
 */
var Projects = function () {
    new CompanyAutoComplete();

    $("#budget").change(function() {
        $(this).val(App.formatPrice(parseInt($(this).val())));
    });


    // Project/Person Functionality
    new PersonFunctionalityUpdate();

    // Project costs
    new ProjectCosts();
};

var CompanyAutoComplete = function () {
    // Autocomplete Company
    $('#company_textfield').autocomplete({
        serviceUrl: '/getCompanies',
        dataType: 'json',
        onSelect: function (suggestion) {
            var companyId = suggestion.data;
            $('#company').val(companyId);
            $('#persons').html('');
            $('#fakturas').html('');
            $('#company_textfield').trigger("onSelect");
        }
    }).keyup(function () {
        //Reset Company ID if nothing entered
        if ($('#company_textfield').val() == '' && $('#company').val() != '') {
            $('#company').val('');
            $('#persons').html('');
            $('#fakturas').html('');
            $("#company_textfield").trigger("textfieldResetted");
        }
    });
};

var AgencyAutoComplete = function () {
    // Autocomplete Agency
    $('#agency_textfield').autocomplete({
        serviceUrl: '/getCompanies',
        dataType: 'json',
        onSelect: function (suggestion) {
            var companyId = suggestion.data;
            $('#agency').val(companyId);
            $('#persons').html('');
            $('#fakturas').html('');
            $('#agency_textfield').trigger("onSelect");
        }
    }).keyup(function () {
        //Reset Company ID if nothing entered
        if ($('#agency_textfield').val() == '' && $('#agency').val() != '') {
            $('#agency').val('');
            $('#persons').html('');
            $('#fakturas').html('');
            $("#agency_textfield").trigger("textfieldResetted");
        }
    });
};

var EmployeeAutoComplete = function () {
    // Autocomplete Agency
    $('#employee_textfield').autocomplete({
        serviceUrl: '/getEmployees',
        dataType: 'json',
        onSelect: function (suggestion) {
            var employeeId = suggestion.data;
            $('#employee').val(employeeId);
            $('#employee_textfield').trigger("onSelect");
        }
    }).keyup(function () {
        //Reset Employee ID if nothing entered
        if ($('#employee_textfield').val() == '' && $('#employee').val() != '') {
            $('#employee').val('');
        }
    });
};

/**
 * All fieldmasks functions.
 *
 * @constructor
 */
var FieldMasks = function () {
    // Ajax From handling
    new AjaxFormSubmit();

    // Handles Field adding and deletion
    new FieldHandling();
};

/**
 * All workmask functions
 *
 */
var Work = {
    totalcosts : 0,
    totaltime : 0,
    totaltime_nobillable : 0,
    totaltime_billable : 0,
    start_time : $("#start_time"),
    end_time : $("#end_time"),
    total_time : $("#total_time"),

    init: function() {
        if(projectId = $('#project').val()) {
            var workId = $('[name=id_work]').val();
            Work.loadFields(projectId, workId);
        }
        //Bind fields with function
        Work.refreshProjectAutoComplete();

        Work.start_time.keyup(Work.calculateTime);
        Work.end_time.keyup(Work.calculateTime);
        Work.total_time.keyup(Work.calculateTime);

        Work.start_time.keyup(Work.calculateCost);
        Work.end_time.keyup(Work.calculateCost);
        Work.total_time.keyup(Work.calculateCost);

        $("#billable").change(Work.calculateCost);

        Work.start_time.numeric({decimal: ":"});
        Work.end_time.numeric({decimal: ":"});
        Work.total_time.numeric({decimal: ":"});

        //Auto calculate costs
        $(".workitem").each(function () {
            var cost = 0;
            if ($(".billable", this).val() == 1 && Work.checkValidTime($(".total_time", this))) {
                cost = ($(".rate_value", this).val() * Work.getHour($(".total_time", this))) + (($(".rate_value", this).val() * Work.getMinute($(".total_time", this))) / 60);
                Work.totaltime_billable += Work.getHour($(".total_time", this)) + (Work.getMinute($(".total_time", this)) / 60);
            } else {
                Work.totaltime_nobillable += Work.getHour($(".total_time", this)) + (Work.getMinute($(".total_time", this)) / 60);
            }
            $(".costs", this).html(App.formatPrice(cost));
            Work.totalcosts += cost;
        });
        Work.totaltime = Work.totaltime_billable + Work.totaltime_nobillable;
        $("#total_costs").html(App.formatPrice(Work.totalcosts));
        $("#total_time").html(Work.totaltime.toFixed(1));
        $("#total_time_billable").html(Work.totaltime_billable.toFixed(1));
        $("#total_time_nobillable").html(Work.totaltime_nobillable.toFixed(1));

        // Autocomplete Companies
        $('#company_textfield').autocomplete({
            serviceUrl: '/getCompanies',
            dataType: 'json',
            onSelect: function (suggestion) {
                var companyId = suggestion.data;
                $('#company').val(companyId);

                $('#project').val('');

                Work.resetPersonSelect();
                $('#project_textfield').val('');
                Work.refreshProjectAutoComplete();
                Work.checkCompanyHasProjects(companyId);
            }
        }).keyup(function () {
            //Reset Company ID if nothing entered
            if ($('#company_textfield').val() == '' && $('#company').val() != '') {
                $('#company').val('');
                Work.resetPersonSelect();
                $('#project_textfield').val('');
                Work.refreshProjectAutoComplete();
            }
        });

        //Calculate Costs when rate is changed
        $("#valuation").change(function() {
            var selected_valuation = $('#valuation :selected');
            $("#rate_textfield").val(selected_valuation.text());
            $("#rate_value").val(selected_valuation.attr('data-ratevalue'));
            Work.calculateCost();
        });
    },

    loadFields: function(projectId, workId) {
        Work.getPersons(projectId, workId);
        Work.getProjectCosts(projectId, workId);
    },

    getDateDiff: function (d1, d2) {
        d2.setHours(d2.getHours() - d1.getHours());
        d2.setMinutes(d2.getMinutes() - d1.getMinutes());
        return {calculated_hours: d2.getHours(), calculated_minutes: d2.getMinutes()};
    },
    getDateAdd: function (d1, d2) {
        d1.setHours(d1.getHours() + d2.getHours());
        d1.setMinutes(d1.getMinutes() + d2.getMinutes());
        return {calculated_hours: d1.getHours(), calculated_minutes: d1.getMinutes()};
    },
    getDateSub: function getDateSub(d1, d2) {
        d1.setHours(d1.getHours() - d2.getHours());
        d1.setMinutes(d1.getMinutes() - d2.getMinutes());
        return {calculated_hours: d1.getHours(), calculated_minutes: d1.getMinutes()};
    },
    checkValidTime: function (time_field) {
        //add : if length
        if (time_field.val().length >= 4 && time_field.val().indexOf(':') == -1) {
            time_field.val(time_field.val().substr(0, 2) + ':' + time_field.val().substr(2, 5));
        }
        if (Work.getMinute(time_field) == -1 || Work.getHour(time_field) == -1) {
            return false;
        }
        return true;
    },
    getTimeArray: function (time_field) {
        var time_input = time_field.val();
        // Split up Time
        var time_array = time_input.split(':');
        if (!$.isArray(time_array) || time_array.length != 2) {
            return false;
        }
        return time_array;
    },
    getHour: function (time_field) {
        var time_array = Work.getTimeArray(time_field);
        if (time_array == false) {
            return -1;
        }
        var hour = parseInt(time_array[0]);
        if (isNaN(hour) || hour < 0 || hour >= 24) {
            return -1;
        }
        return hour;
    },
    getMinute: function(time_field) {
        var time_array = Work.getTimeArray(time_field);
        if (time_array == false) {
            return -1;
        }
        if(time_array[1].length != 2) {
            return -1;
        }
        var minute = parseInt(time_array[1]);
        if (isNaN(minute) || minute < 0 || minute >= 60) {
            return -1;
        }
        return minute;
    },
    calculateCost: function () {
        var cost = 0;
        if ($("#billable").val() == 1 && Work.checkValidTime(Work.start_time) && Work.checkValidTime(Work.end_time) && Work.checkValidTime(Work.total_time)) {
            cost = ($("#rate_value").val() * Work.getHour(Work.total_time)) + (($("#rate_value").val() * Work.getMinute(Work.total_time)) / 60);
        }

        $("#costs_number").html(App.formatPrice(cost));
        $("#costs").val(cost);
    },
    calculateTime: function () {
        // Check Current entered time
        if (Work.checkValidTime($(this))) {
            //calculate time
            var calculate_field = null;
            var calculated_hours = null;
            var calculated_minutes = null;
            var diff = null;

            if ($(this).attr('id') == 'start_time') {
                if (Work.checkValidTime(Work.end_time)) {
                    //calculate total time
                    diff = Work.getDateDiff(new Date(2000, 1, 1, Work.getHour(Work.start_time), Work.getMinute(Work.start_time), 0), new Date(2000, 1, 1, Work.getHour(Work.end_time), Work.getMinute(Work.end_time), 0));
                    calculated_hours = diff.calculated_hours;
                    calculated_minutes = diff.calculated_minutes;

                    calculate_field = Work.total_time;
                } else if (Work.checkValidTime(Work.total_time)) {
                    //calculate end time
                    diff = Work.getDateAdd(new Date(2000, 1, 1, Work.getHour(Work.start_time), Work.getMinute(Work.start_time), 0), new Date(2000, 1, 1, Work.getHour(Work.total_time), Work.getMinute(Work.total_time), 0));
                    calculated_hours = diff.calculated_hours;
                    calculated_minutes = diff.calculated_minutes;

                    calculate_field = Work.end_time;
                }
            } else if ($(this).attr('id') == 'end_time') {
                if (Work.checkValidTime(Work.start_time)) {
                    //calculate total time
                    diff = Work.getDateDiff(new Date(2000, 1, 1, Work.getHour(Work.start_time), Work.getMinute(Work.start_time), 0), new Date(2000, 1, 1, Work.getHour(Work.end_time), Work.getMinute(Work.end_time), 0));
                    calculated_hours = diff.calculated_hours;
                    calculated_minutes = diff.calculated_minutes;

                    calculate_field = Work.total_time;
                } else if (Work.checkValidTime(Work.total_time)) {
                    //calculate start time
                    diff = Work.getDateSub(new Date(2000, 1, 1, Work.getHour(Work.end_time), Work.getMinute(Work.end_time), 0), new Date(2000, 1, 1, Work.getHour(Work.total_time), Work.getMinute(Work.total_time), 0));
                    calculated_hours = diff.calculated_hours;
                    calculated_minutes = diff.calculated_minutes;

                    calculate_field = Work.start_time;
                }
            } else if ($(this).attr('id') == 'total_time') {
                if (Work.checkValidTime(Work.start_time)) {
                    //calculate end time
                    diff = Work.getDateAdd(new Date(2000, 1, 1, Work.getHour(Work.start_time), Work.getMinute(Work.start_time), 0), new Date(2000, 1, 1, Work.getHour(Work.total_time), Work.getMinute(Work.total_time), 0));
                    calculated_hours = diff.calculated_hours;
                    calculated_minutes = diff.calculated_minutes;

                    calculate_field = Work.end_time;
                } else if (Work.checkValidTime(Work.end_time)) {
                    //calculate start time
                    diff = Work.getDateSub(new Date(2000, 1, 1, Work.getHour(Work.end_time), Work.getMinute(Work.end_time), 0), new Date(2000, 1, 1, Work.getHour(Work.total_time), Work.getMinute(Work.total_time), 0));
                    calculated_hours = diff.calculated_hours;
                    calculated_minutes = diff.calculated_minutes;

                    calculate_field = Work.start_time;
                }
            }

            if (calculate_field && calculated_hours < 24 && calculated_minutes < 60) {
                calculate_field.val(("0" + calculated_hours).slice(-2) + ":" + ("0" + calculated_minutes).slice(-2));
            }
        }
    },
    refreshProjectAutoComplete: function () {
        Work.resetProjectCostSelect();
        $('#project_textfield').autocomplete({
            serviceUrl: '/getProjects/' + $('#company').val(),
            dataType: 'json',
            onSelect: function (suggestion) {
                var projectId = suggestion.data;
                //update fields
                $('#company_textfield').val(suggestion.company_name);
                $('#company').val(suggestion.company_id);
                $('#project').val(projectId);
                $('#projectname-header').html(suggestion.value);
                $('.project-header').css('display','inline');
                Work.getPersons(projectId);
                //get project costs
                Work.getProjectCosts(projectId);
            }
        }).keyup(function () {
            //Reset Company ID if nothing entered
            if ($('#project_textfield').val() == '' && $('#project').val() != '') {
                $('#project').val('');
                Work.resetPersonSelect();
                Work.resetProjectCostSelect();
            }
        });
    },
    resetPersonSelect: function () {
        $("#person optgroup").remove();
        $("#person option").remove();
        $("#person").prop('disabled', true);
    },
    resetProjectCostSelect: function () {
        $("#valuation option:not(.standard_rate)").remove();
        $("#valuation_box").css('display', 'none');
        $("#rate_textfield").val($("#default_rate_text").val());
        $("#rate_value").val($("#default_rate").val());
        Work.calculateCost();
    },
    checkCompanyHasProjects: function (companyId) {
        $.getJSON('/checkCompanyHasProjects/' + companyId, function (data) {
            if (data.projectfound == false) {
                notifyUser('danger', data.msg);
            }
        });
    },
    // Autocomplete Persons
    getPersons: function (projectId, workId) {
        $.getJSON('/getPersons/' + projectId + ((workId) ? '/' + workId : ''), function (groups) {
            Work.resetPersonSelect();
            for (var g = 0; g < groups.length; g++) {
                var options = groups[g].options;
                if (options !== undefined) {
                    $("#person").append('<optgroup label="' + groups[g].label + '">');
                    for (var opt = 0; opt < options.length; opt++) {
                        $("#person").append('<option value="' + options[opt].value + '" ' + ((options[opt].selected) ? 'selected="selected"' : '') + '>' + options[opt].name + '</option>');
                    }
                    $("#person").append('</optgroup>');
                }
            }
            $("#person").prop('disabled', false);
        });
    },
    getProjectCosts: function (projectId, workId) {
        $.getJSON('/getProjectCosts/' + projectId + ((workId) ? '/' + workId : ''), function (options) {
            for (var o = 0; o < options.length; o++) {
                $("#valuation").append('<option value="' + options[o].value + '" data-ratevalue="' + options[o].ratevalue + '" ' + ((options[o].selected) ? 'selected="selected"' : '') + '>' + options[o].name + '</option>');
            }
            $("#valuation_box").css('display', 'inline');
            $("#valuation").change();
        }).fail(function(jqxhr, textStatus, error) {
            notifyUser('danger', jqxhr.responseText);
            Work.resetProjectCostSelect();
        });
    }
};

/**
 * Submits forms per ajax request.
 *
 * @constructor
 */
var AjaxFormSubmit = function () {
    var formsubmitted = false;
    $(document).on('submit', '.form-mask', function (e) {
        if (formsubmitted == false) {
            formsubmitted = true;
            $.ajax({
                type: $(this).attr('method'),
                url: $(this).attr('action'),
                data: $(this).serialize(),
                dataType: 'json',
                success: function (data) {
                    if(data.msg) {
                        notifyUser('success', data.msg);
                        $('.form-mask').trigger('submit-successful', data);
                    }
                    if (data.urlforward) {
                        setTimeout(
                            (function(){
                                window.location = data.urlforward;
                            }), 1800);
                    } else {
                        formsubmitted = false;
                    }
                },
                error: function (data) {
                    var errors = data.responseJSON;
                    var errorsHtml = '';
                    $.each(errors, function (key, value) {
                        errorsHtml += value[0] + '<br />';
                    });
                    console.log(errors);
                    notifyUser('danger', errorsHtml);
                    formsubmitted = false;
                    $('.form-mask').trigger('submit-failed');
                }
            });
        }
        e.preventDefault();
    });
};

/**
 * Handles Adding new Fields and Delete Fields.
 *
 * @constructor
 */
var FieldHandling = function () {
    updateGroupedFields();
    var groupedfields = $('.groupedfields');

    groupedfields.delegate('.newField', 'click', function () {
        var formgroup = $(this).closest('.form-group');
        formgroup.clone(false).insertAfter(formgroup);
        //Reset input
        formgroup.next().find('input').val('');

        //Reset datepicker
        $('.datepicker').datepicker({
            format: 'dd.mm.yyyy'
        });
        updateGroupedFields();
    });

    groupedfields.delegate('.delField', 'click', function () {
        if(typeof $(this).attr('disabled') == 'undefined' || $(this).attr('disabled') != 'disabled') {
            $(this).closest('.form-group').remove();
            updateGroupedFields();
        }
    });

    $(document).on('click', '.confirm-button', function(e) {
        return confirm($(this).attr('data-text'));
    });

    var formMask = $('.form-mask');
    formMask.delegate('.checkbox-mask input[type="checkbox"]', 'click', function () {
        if($(this).prop('checked')) {
            //Remove hidden field
            $(this).closest('.checkbox-mask').find('input[type="hidden"]').remove();
        } else {
            //Add hidden field
            $(this).closest('.checkbox-mask').append('<input type="hidden" name="' + $(this).attr('name') + '" value="0">');
        }
    });

    function updateGroupedFields() {
        //Count formgroups in groupedfields
        $('.groupedfields').each(function( index ) {
            if($(this).find('.form-group').size() == 1) {
                //deactivate delete button
                $(this).find('.delField').attr('disabled', true);
            } else {
                //activate delete button
                $(this).find('.delField').removeAttr('disabled');
            }
        });
    }

};

/**
 * Update function for on change person functionality in project.
 *
 * @constructor
 */
var PersonFunctionalityUpdate = function () {
    $('select.person-functionality').change(function () {
        $.ajax({
            method: 'POST',
            url: '/projects/updatePersonFunctionality',
            data: $(this).serialize(),

            success: function (data) {
                notifyUser('success', data.msg);
            },

            error: function (data) {
                notifyUser('danger', data.msg);
            }
        });
    });
};

/**
 * Project costs total amount calculation.
 *
 * @constructor
 */
var ProjectCosts = function () {
    // On change of valuation or hours input
    new ValuationHoursChange();

    // On change of cost type select box
    new ProjectCostTypeChange();
};

/**
 * On change of valuation or hours input, recalculate total costs.
 *
 * @constructor
 */
var ValuationHoursChange = function() {
    // Valuation input change
    $("input[name='valuation']").change(function () {
        if ($("input[name='hours']").val() != '') {
            $("#total_amount").val(
                totalAmount($(this).val(), $("input[name='hours']").val())
            );
        } else {
            $("#total_amount").val(
                totalAmount($(this).val(), 1)
            );
        }
    });

    // Hours input change
    $("input[name='hours']").change(function () {
        if ($("input[name='valuation']").val() != '') {
            $("#total_amount").val(
                totalAmount($("input[name='valuation']").val(), $(this).val())
            );
        } else {
            $("#total_amount").val(
                totalAmount(1, $(this).val())
            );
        }
    });
};

/**
 * Calculate total amount of cost out of valuation and hours.
 *
 * @param valuation
 * @param hours
 * @returns {string}
 */
var totalAmount = function (valuation, hours) {
    return (valuation * hours).toFixed(2);
};

/**
 * On change of projcet cost type, set total amount field value.
 *
 * @constructor
 */
var ProjectCostTypeChange = function() {
    $('#cost_type').change(function(){
        $('#total_amount').val(totalProjectCost($("input[name='valuation']"), $("input[name='hours']"), $(this).find(':selected').val()));
    });
};

/**
 * Handle project cost type changes.
 *
 * @param valuation
 * @param hours
 * @param condition
 * @returns {number}
 */
var totalProjectCost = function (valuation, hours, condition) {
    switch (parseInt(condition)) {
        case 3:
            $('#total_amount').prop('readonly', false);
            $("input[name='valuation']").prop('disabled', true);
            $("input[name='hours']").prop('disabled', true);
            return 0;
        default:
            $('#total_amount').prop('readonly', true);
            $("input[name='valuation']").prop('disabled', false);
            $("input[name='hours']").prop('disabled', false);
            return totalAmount(valuation, hours);
    }
};

/**
 * Bootstrap Notify to notify user about changes.
 *
 * @param type Message type according to bootstrap brand colors.
 * @param data Message text.
 */
function notifyUser(type, data) {
    $.notify({
        message: data
    }, {
        type: type,
        delay: 5000,
        newest_on_top: true
    });
}